import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import React from 'react'
import { theme } from '../styles/theme'

const Pages = [
  { code: 'start_page', url: '/', key: 1 },
  { code: 'all_services', url: '/schedule', key: 2 },
  { code: 'about', url: '/about', key: 3 },
  { code: 'activities', url: '/activities', key: 4 },
  { code: 'organisation', url: '/organisation', key: 5 },
  { code: 'faq', url: '/faq', key: 6 },
]

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p style={{ textAlign: 'left' }}>{children}</p>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 style={{ textAlign: 'left', color: theme.colors.caramel }}>
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4
        style={{ textAlign: 'left', color: theme.colors.caramelCloudIntense }}>
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <h5 style={{ textAlign: 'left', color: theme.colors.caramel }}>
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <h6 style={{ textAlign: 'left', color: theme.colors.caramel }}>
        {children}
      </h6>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node.data.uri}
        style={{ color: theme.colors.caramel }}
        target='_blank'
        rel='noopener noreferrer'>
        {children}
      </a>
    ),
  },
}

export { Pages, options }
