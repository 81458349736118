import {graphql, StaticQuery} from 'gatsby'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import React, {useState} from 'react'
import ScrollLock from 'react-scrolllock'
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components'
import MobileMenu from '../components/mobileMenu'
import {theme} from '../styles/theme'
import {GTMobile, Mobile} from '../utils/responsive'
import Footer from './footer'
import Header from './header'
import Announcement from "./announcement";

const GlobalStyle = createGlobalStyle`
    html {
        overflow-y: initial;
    }

    body {
        background-color: ${theme.colors.cream};
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`
const Container = styled.div`
    max-width: 70rem;
    margin: 0 auto;
    position: relative;
`

const InnerContainer = styled.div`
    padding-block-start: 4.5rem;
    @media all and (max-width: 600px) {
        padding-block-start: 3.5rem;
    }
`

const Main = styled.main`
    position: relative;
    padding: 0.5rem;
`

const baseTitleStyles = {
    margin: 0,
    color: `${theme.colors.caramel}`,
    textAlign: `center`,
    background: `${theme.colors.cream}`,
    transition: `all 1s ease-in-out`
}

const titleStyles = {
    minHeight: `3.5rem`, borderRadius: '4px', padding: '0.75rem'
}

const subtitleStyles = {
    paddingBlockStart: `0.75rem`
}

const Layout = ({children, location}) => {

    const intl = useIntl()

    let [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)
    const openMenu = () => setMobileMenuIsOpen((mobileMenuIsOpen = !mobileMenuIsOpen))
    const closeMenu = () => setMobileMenuIsOpen((mobileMenuIsOpen = false))

    return (<StaticQuery
        query={
            graphql`
                    query SiteTitleQuery {
                      site {
                        siteMetadata {
                          title
                        }
                      }
                    }
                  `
        }
        render={() => {
            return (<ThemeProvider theme={theme}>
                <GlobalStyle/>
                <ScrollLock isActive={mobileMenuIsOpen}/>
                <Container>
                    <Header
                        intl={intl}
                        openMenu={openMenu}
                        open={mobileMenuIsOpen}
                    />
                    <InnerContainer>
                        <GTMobile>
                            <h1 style={{...baseTitleStyles, ...titleStyles}}>
                                <FormattedMessage id={`title`}/>
                            </h1>
                            <h3 style={{...baseTitleStyles, ...subtitleStyles}}>
                                <FormattedMessage id={`subtitle`}/>
                            </h3>
                        </GTMobile>
                        <Mobile>
                            <h2 style={{...baseTitleStyles, ...titleStyles}}>
                                <FormattedMessage id={`title`}/>
                            </h2>
                            <h4 style={{...baseTitleStyles, ...subtitleStyles}}>
                                <FormattedMessage id={`subtitle`}/>
                            </h4>
                        </Mobile>
                        <Main>
                            <Announcement locale={intl.locale}/>
                            {children}
                        </Main>
                        <Footer payment={location && location.includes('payment')}/>
                    </InnerContainer>
                    <MobileMenu active={mobileMenuIsOpen} close={closeMenu}/>
                </Container>
            </ThemeProvider>)
        }}
    />)
}

export default Layout
