import {StaticImage} from "gatsby-plugin-image";
import {FormattedMessage} from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import {StyledLink} from '../styles/common'
import FacebookIcon from './facebook';
import YouTubeIcon from './youtube';
import InnerHTML from 'dangerously-set-html-content'

const Toolbar = styled.nav`
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  @media all and (max-width: 767px) {
    padding: 1.5rem 0;
  }
  background: ${(props) => props.theme.colors.coffeeWithMilk};
`

const StyledExternalLink = styled.a`
  background-image: none;
  cursor: pointer;
  color: transparent;
  padding: 0.75rem 0 0.5rem;
`

const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  top: 20px;
`

const PaymentLink = styled.div`
  width: fit-content;
`

const Level = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: linear-gradient(135deg,
  ${(props) => props.theme.colors.coffee} 0%,
  ${(props) => props.theme.colors.cream} 100%);
  min-height: 5.5rem;
  max-width: 100%;
  overflow: hidden;
  @media all and (max-width: 824px) {
    flex-direction: column;
    padding-block-start: 1.5rem;
  }
`

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;

  p {
    margin-bottom: 0.25rem;
    text-align: center;
  }

  padding: 32px 16px;
  @media all and (max-width: 600px) {
    padding: 16px;
  }
  color: ${(props) => props.theme.colors.caramel};
  @media all and (min-width: 825px) {
    max-width: 33%;
  }

  h3 {
    text-align: center;
  }
`

const MailLink = styled.a`
  color: ${(props) => props.theme.colors.caramel};
`

const html = `
    <div id="donate-button-container">
        <div id="donate-button">
            <script>
                PayPal.Donation.Button({
                env: 'production',
                hosted_button_id: 'WFTWEKNSVP9A8',
                image: {
                src: 'https://www.paypalobjects.com/nl_NL/NL/i/btn/btn_donateCC_LG.gif',
                alt: 'Doneren met PayPal-knop',
                title: 'PayPal - The safer, easier way to pay online!',
            }}).render('#donate-button')
            </script>
        </div>
    </div>
`

const Footer = ({payment}) => {
    return (
        <StyledFooter>
            <Toolbar>
                <StyledExternalLink
                    aria-label='Facebook icon'
                    href='https://www.facebook.com/orthodoxchurchamsterdam/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FacebookIcon width={40}/>
                </StyledExternalLink>
                <StyledExternalLink
                    aria-label='YouTube icon'
                    href='https://www.youtube.com/channel/UC3AOYJ8nDIv2GsO3Rf_CYwA/videos'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <YouTubeIcon width={40}/>
                </StyledExternalLink>
                <InnerHTML html={html}/>
                {!payment && (
                    <PaymentLink>
                        <StyledLink to={`/payment`}>
                            <FormattedMessage id={'payment'}/>
                        </StyledLink>
                    </PaymentLink>
                )}
            </Toolbar>
            <Level>
                <Cell>
                    <StaticImage
                        src={`../images/icon.svg`}
                        style={{width: '124px', marginBottom: 0}}
                        alt={'Icon'}/>
                    <h3>
                        <FormattedMessage id={`title`}/>
                    </h3>
                </Cell>
                <Cell>
                    <p>Lijnbaansgracht 47-48</p>
                    <p>1015 GR Amsterdam</p>
                    <p>tel: +31 (0) 6 27 36 86 75</p>
                    <p>
                        <MailLink href='mailto:office@orthodox-amsterdam.nl'>
                            e-mail: office@orthodox-amsterdam.nl
                        </MailLink>
                    </p>
                </Cell>
                <Cell>
                    <div>
                        <p>IBAN: NL25 INGB 0004 1636 83</p>
                        <p>BIC (SWIFT): INGBNL2A</p>
                        <p>t.n.v. Orthodoxe Parochie H. Nicolaas (Amsterdam)</p>
                    </div>
                </Cell>
            </Level>
        </StyledFooter>
    );
}

export default Footer
