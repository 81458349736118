import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import React, {useContext, useEffect, useState} from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import {CloseIcon} from './icons'
import {theme} from '../styles/theme'
import {GlobalDispatchContext, GlobalStateContext} from "../context/GlobalContextProvider";
import {options} from "../styles/options";

const Article = styled.article`
  text-align: left;

  p {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    text-align: left;
  }
`

const Button = styled.button`
  position: absolute;
  right: 4px;
  top: 8px;
  border: none;
  background: transparent;

  :focus {
    outline: none;
  }

  z-index: 100;
`

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.58)',
        minHeight: '100%',
    },
    content: {
        position: 'relative',
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        margin: '32px auto',
        padding: '32px',
        border: 0,
        overflow: 'scroll',
        minHeight: '50px',
        backgroundColor: '#b84e19',
    },
}

Modal.setAppElement('#___gatsby')

const Announcement = ({locale}) => {
    const globalState = useContext(GlobalStateContext)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [announcement, setAnnouncement] = useState({})

    let [seen, setSeen] = useState(globalState[locale])

    const dispatch = useContext(GlobalDispatchContext)

    useEffect(() => {
        function checkAnnouncement() {
            setSeen(globalState[locale])
        }

        checkAnnouncement()
    }, [globalState, locale])

    useEffect(() => {
        let a = {}
        let b = true
        async function fetchAnnouncement() {
            const response = await fetch(
                `https://cdn.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.CONTENTFUL_ACCESS_TOKEN}&content_type=announcement`,
            )
            const {items} = await response.json()
            if (items && items.length) {
                a = items[0]
            }
        }

        fetchAnnouncement().then(() => {
            if (b && !seen) {
                setAnnouncement(a)
                setModalIsOpen(true)
            }
        })

        return () => b = false
    }, [dispatch, locale, seen])

    const closeModal = () => {
        dispatch({type: `SET_${locale.toUpperCase()}_SEEN`})
        setModalIsOpen(false)
    }


    return (
        <>
            {announcement.fields && (
                <Modal
                    isOpen={modalIsOpen}
                    contentLabel='Announcement modal'
                    style={modalStyles}
                    closeTimeoutMS={300}>
                    <Button onClick={closeModal}>
                        <CloseIcon size={40} color={theme.colors.cream}/>
                    </Button>
                    <Article>
                        {documentToReactComponents(announcement.fields[locale], options)}
                    </Article>
                </Modal>
            )}
        </>
    )
}

export default Announcement
