import { IntlContextConsumer, Link } from 'gatsby-plugin-intl'
import React from 'react'
import withSizes from 'react-sizes'
import styled from 'styled-components'

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

const StyledLinkWithHover = styled(Link)`
  color: ${props => props.theme.colors.caramel};
  background: ${props => props.theme.colors.creamCloud};
  text-decoration: none;
  margin-right: 15px;
  border-radius: 4px;
  padding: 8px 16px;
  transition: all 0.2s ease;
  outline: none;
  &:hover {
    border: 1px solid ${props => props.theme.colors.cream};
    background: ${props => props.theme.colors.cream};
    text-decoration: none !important;
  }
`

const languageName = {
  nl: 'Nederlands',
  ru: 'Русский',
  en: 'English',
}

const Language = ({ isMobile }) => (
  <div>
    <IntlContextConsumer>
      {({ languages, originalPath }) =>
        languages.map(language => (
          <StyledLinkWithHover
            key={language}
            language={language}
            to={originalPath}>
            {isMobile ? language : languageName[language]}
          </StyledLinkWithHover>
        ))
      }
    </IntlContextConsumer>
  </div>
)

export default withSizes(mapSizesToProps)(Language)
