import React from 'react'

import Responsive from 'react-responsive'

export const MiniTouch = (props) => (
  <Responsive {...props} maxWidth={360} />
)

export const MidiTouch = (props) => (
  <Responsive {...props} minWidth={361} maxWidth={599} />
)

export const GTMidiTouch = (props) => (
  <Responsive {...props} minWidth={600} />
)

export const LTTablet = (props) => (
  <Responsive {...props} maxWidth={767} />
)

export const GTTablet = (props) => (
  <Responsive {...props} minWidth={768} />
)

export const Mobile = (props) => <Responsive {...props} maxWidth={600} />

export const GTMobile = (props) => <Responsive {...props} minWidth={601} />
