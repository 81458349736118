import React from 'react'

const ArrowDropDown = ({ size, color }) => (
  <svg
    style={{ marginBottom: '-20px', marginTop: '-20px' }}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'>
    <path d='M7 10l5 5 5-5z' fill={color} />
    <path d='M0 0h24v24H0z' fill='none' />
  </svg>
)

const ArrowDropUp = ({ size, color }) => (
  <svg
    style={{ marginBottom: '-20px', marginTop: '-20px' }}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'>
    <path d='M7 14l5-5 5 5z' fill={color} />
    <path d='M0 0h24v24H0z' fill='none' />
  </svg>
)

const ArrowRight = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 24 24'>
    <path fill='none' d='M0 0h24v24H0z' />
    <path d='M16.01 11H4v2h12.01v3L20 12l-3.99-4z' fill={color} />
  </svg>
)

const ChevronLeft = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'>
    <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' fill={color} />
    <path d='M0 0h24v24H0z' fill='none' />
  </svg>
)

const ChevronRight = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'>
    <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill={color} />
    <path d='M0 0h24v24H0z' fill='none' />
  </svg>
)

const ForwardBurger = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M19,13H3V11H19L15,7L16.4,5.6L22.8,12L16.4,18.4L15,17L19,13M3,6H13V8H3V6M13,16V18H3V16H13Z'
    />
  </svg>
)

const BackBurger = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M5,13L9,17L7.6,18.42L1.18,12L7.6,5.58L9,7L5,11H21V13H5M21,6V8H11V6H21M21,16V18H11V16H21Z'
    />
  </svg>
)

const DownloadIcon = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'>
    <path d='M0 0h24v24H0z' fill='none' />
    <path
      d='M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z'
      fill={color}
    />
  </svg>
)

const CloseIcon = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height={size}
    viewBox='0 0 24 24'
    width={size}>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      d='M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z'
      fill={color}
    />
  </svg>
)

export {
  ArrowDropDown,
  ArrowDropUp,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
  ForwardBurger,
  BackBurger,
  DownloadIcon,
  CloseIcon,
}
