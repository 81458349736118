import { Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.cream};
  background: ${props => props.theme.colors.caramel};;
  text-decoration: none;
  margin-right: 15px;
  border: 1px solid ${props => props.theme.colors.caramel};
  border-radius: 4px;
  padding: 8px 16px;
  transition: all 0.2s ease;
  outline: none;
`

const StyledLinkWithHover = styled(StyledLink)`
  &&& {
    :hover {
      border-color: ${props => props.theme.colors.cream};
      color: ${props => props.theme.colors.cream};
    }
  }
`

const StyledLinkWithInvertedHover = styled(Link)`
  text-decoration: none;
  margin-right: 15px;
  border-radius: 4px;
  padding: 8px 16px;
  transition: all 0.2s ease;
  outline: none;
  &&& {
    width: 45%;
    @media (min-width: 960px) {
      width: 17.5%;
    }
    text-align: center;
    border: none;
    color: ${(props) => props.theme.colors.caramel};
    background: ${(props) => props.theme.colors.creamCloud};
    :hover {
      border-color: ${(props) => props.theme.colors.cream};
      color: ${(props) => props.theme.colors.caramel};
      background: ${(props) => props.theme.colors.cream};
    }
  }
`

export { StyledLink, StyledLinkWithHover, StyledLinkWithInvertedHover };

