import { graphql, StaticQuery } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import React from 'react'
import ReactHoverObserver from 'react-hover-observer'
import styled from 'styled-components'
import { theme } from '../styles/theme'
import { GTMobile, GTTablet, LTTablet, Mobile } from '../utils/responsive'
import { BackBurger, ForwardBurger } from './icons'
import Language from './language'
import SvgLogo from './logo'

const Header = styled.header`
    padding-left: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    min-height: 4.5rem;
    transition: all 0.5s ease;
    position: relative;
    @media all and (max-width: 599px) {
        min-height: 3.5rem;
    }
    @media all and (max-width: 767px) {
        padding: 0 86px;
    }
`

const LanguageButtons = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  @media all and (max-width: 599px) {
    padding: 0;
  }
  right: 0;
  z-index: 10;
`
const Button = styled.button`
  position: absolute;
  right: 2.5%;
  top: 20%;
  z-index: 20;
  border: none;
  background: transparent;
  :focus {
    outline: none;
  }
`
const StartPageLink = styled.h2`
  margin: 0;
  transform: translateY(50%);
`

const HeaderComponent = ({ intl, isMobile, openMenu, open }) => (
  <StaticQuery
    query={graphql`query HeaderQuery {
  file(relativePath: {eq: "icon.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 128, layout: CONSTRAINED)
    }
  }
}
`}
    render={() => {
      const link = `/${intl.locale}`

      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            zIndex: 200,
            background: theme.colors.coffee,
            maxWidth: '70rem',
            margin: '0 auto',
          }}>
          <Header>
            <ReactHoverObserver>
              {({ isHovering }) => (
                <AniLink fade to={link} style={{ cursor: 'pointer' }}>
                  <Mobile>
                    <SvgLogo
                      size='124px'
                      color={
                        !isHovering ? theme.colors.cream : theme.colors.caramel
                      }
                    />
                  </Mobile>
                  <GTMobile>
                    <SvgLogo
                      size='196px'
                      color={
                        !isHovering ? theme.colors.cream : theme.colors.caramel
                      }
                    />
                    <GTTablet>
                      <StartPageLink
                        style={{
                          color: !isHovering
                            ? theme.colors.cream
                            : theme.colors.caramel,
                        }}>
                        {intl.formatMessage({ id: 'start_page' })}
                      </StartPageLink>
                    </GTTablet>
                     
                  </GTMobile>
                </AniLink>
              )}
            </ReactHoverObserver>
            <LanguageButtons>
              <Language isMobile={isMobile} />
            </LanguageButtons>
            <LTTablet>
              <Button onClick={openMenu}>
                {open ? (
                  <ForwardBurger size={36} color={theme.colors.caramel} />
                ) : (
                  <BackBurger size={36} color={theme.colors.caramel} />
                )}
              </Button>
            </LTTablet>
          </Header>
        </div>
      )
    }}
  />
)

export default HeaderComponent
