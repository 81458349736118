import { injectIntl } from 'gatsby-plugin-intl'
import React, { useRef } from 'react'
import { TouchScrollable } from 'react-scrolllock'
import { config, useChain, useSpring, useTransition } from 'react-spring'
import { Container, Item, StyledLink } from '../styles/mobileMenu'
import { Pages } from '../utils/pages'

const MobileMenu = ({ intl, active, close }) => {
  const springRef = useRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.default,
    from: { size: '0%', padding: '0px' },
    to: { size: active ? '100%' : '0%', padding: active ? '25px' : '0px' },
  })

  const transRef = useRef()
  const transitions = useTransition(active ? Pages : [], item => item.key, {
    ref: transRef,
    unique: true,
    trail: 100 / Pages.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' },
  })

  useChain(active ? [springRef, transRef] : [transRef, springRef])

  return (
    <TouchScrollable>
      <Container style={{ ...rest, width: size, height: size }} onClick={close}>
        {transitions.map(({ item, key, props }) => {
          const url = `${item.url}/`

          return (
            <StyledLink onClick={close} key={key} to={url}>
              <Item
                style={{
                  ...props,
                }}>
                {intl.formatMessage({ id: item.code })}
              </Item>
            </StyledLink>
          )
        })}
      </Container>
    </TouchScrollable>
  )
}

export default injectIntl(MobileMenu)
