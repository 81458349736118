import React from 'react'
import styled from 'styled-components'
import withSizes from 'react-sizes'

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 600,
})

const Svg = styled.svg`
  position: absolute;
`

const Logo = ({ size, color, isMobile }) => (
  <Svg
    width={size}
    height={size}
    style={
      isMobile
        ? { left: '-30px', top: '-30px' }
        : { left: '-50px', top: '-50px' }
    }
    viewBox='-395.719 -312.748 1024 1024'>
    <switch>
      <g>
        <g id='Layer_1'>
          <g>
            <path
              fill={color}
              d='M207.11,187.617l-48.325-21.522v-13.401c0,0,31.268-21.929-6.903-60.103
                           c-38.163-38.162-34.117-27.62-37.766-51.554v-0.02c0,0.002,0,0.005,0,0.008c-0.002-0.003-0.002-0.006-0.002-0.008v0.02
                           c-3.65,23.935,0.397,13.393-37.765,51.554c-38.172,38.174-6.906,60.103-6.906,60.103v13.401l-48.324,21.522
                           C47.514,274.117,0,384.171,0,384.171h102.744h22.74h102.745C228.229,384.171,180.716,274.117,207.11,187.617z M99.09,91.779
                           c10.819-10.279,14.057-20.89,15.026-26.85c0.967,5.96,4.206,16.571,15.024,26.85c16.243,15.431,33.302,30.458,21.931,46.296
                           c-7.111-1.422-27.604-3.429-27.616-3.656c-2.65,0-5.854,0.065-9.338,0.189c-3.486-0.124-9.366,0.229-9.377,0.448
                           c-9.909,0.592-20.469,1.597-27.579,3.019C65.789,122.237,82.847,107.21,99.09,91.779z M102.744,365.489H25.992
                           c30.864-99.901,15.026-169.344,15.026-169.344l42.234-20.304v-23.555c11.895-2.918,23.154-3.6,30.864-3.628
                           c7.708,0.028,18.965,0.71,30.862,3.628v23.555l42.233,20.304c0,0-15.838,69.442,15.028,169.344h-76.755H102.744z'
            />
            <rect x='112.49' fill={color} width='3.251' height='58.276' />
            <rect
              x='101.731'
              y='12.792'
              fill={color}
              width='24.771'
              height='3.858'
            />
            <rect
              x='107.213'
              y='5.889'
              fill={color}
              width='14.62'
              height='3.046'
            />
            <polygon
              fill={color}
              points='121.423,38.174 108.227,31.879 108.227,28.224 121.423,34.52 '
            />
            <path
              fill={color}
              d='M148.434,347.826h-2.235v3.655h2.235v-0.406h3.247v11.574h1.218v-11.574h1.018
                           v-2.235h-5.482V347.826z M130.022,362.649h11.912v-9.544h-11.371L130.022,362.649z M176.047,315.337
                           c-3.856-32.692-16.244-38.986-16.244-38.986l-26.397,23.757H89.547c0,0-1.625-1.625-19.29-25.382
                           c-19.508,7.218-21.585,82.045-21.718,87.924h25.091c0.002-0.242,0.093-14.009,1.904-16.5c1.827-2.514,8.604-2.284,9.898-2.438
                           c1.294-0.151,4.645-0.685,4.645-0.685l0.281-0.076c0,0-1.626-19.9,3.046-30.256c0.609,9.747,0.407,14.824,0.407,14.824
                           s9.948-17.666,11.167-20.307c2.437,0.205,1.421,3.251,1.421,3.251l-3.248,6.092c0,0,3.45-5.077,4.466-5.077
                           s0.609,1.217-0.203,2.842c-0.812,1.625-3.248,6.294-3.248,6.294s-3.962,0.025-3.962,0.713
                           c6.549-0.458,10.813-0.838,10.813,0.15c0,0.991-0.609,1.98-2.057,1.905c-1.447-0.076-7.31,0.381-7.31,0.381
                           s0.533,1.979-0.99,3.579c1.143,0.988,1.371,2.054,0.229,5.102c-1.143,3.045-0.761,8.069-0.761,8.069s0.532-4.416,3.502-6.928
                           c2.97-2.514,3.96-5.559,3.96-6.7c0-1.143,1.142-2.741,2.437-2.438c0.304,3.96,0.533,11.269,0.076,12.259
                           c-0.458,0.989-4.34,8.3-7.386,8.757c-3.045,0.457-4.569,2.895-4.569,2.895l-1.065,1.75c0,0-4.036,1.903-3.122,3.122
                           c0.913,1.218,0.762,4.188-2.133,4.416c-2.893,0.229-5.482-1.903-5.939-6.854c-0.456-4.948-1.751-2.207-1.522-0.074
                           c0.229,2.131,1.6,10.126,2.285,11.498c-0.146,0.173-0.388,0.31-0.668,0.433h29.503l12.029-13.806V308.84h27.412v38.58
                           l12.596,15.229h12.551C179.952,358.437,179.3,342.898,176.047,315.337z M154.32,306.606h-26.804v-5.077h7.311l19.493-17.461
                           V306.606z'
            />
            <path
              fill={color}
              d='M137.265,260.719L93.203,297.47l-1.828-1.827l45.484-38.986
                           c0,0,17.461,12.388,22.74,18.073'
            />
            <path
              fill={color}
              d='M70.866,273.914c0,0,13.402-13.197,21.321-17.055
                           c13.401,11.778,13.401,11.778,13.401,11.778h17.665l-10.557,9.542L91.78,258.892c0,0-11.981,6.091-22.539,17.665'
            />
            <polygon
              fill={color}
              points='139.747,276.066 145.755,270.674 143.049,267.65 136.874,273.191 
                           131.229,267.548 128.356,270.42 133.847,275.91 113.896,293.814 119.973,293.814 136.721,278.784 143.298,285.359 
                           146.17,282.487 '
            />
            <path
              fill={color}
              d='M99.406,270.42l-2.872-2.872l-5.645,5.644l-6.175-5.541l-2.708,3.023l6.009,5.393
                           l-6.422,6.421l2.872,2.872l6.577-6.575l10.503,9.427l3.203-2.58l-10.831-9.721L99.406,270.42z'
            />
            <g>
              <path
                fill={color}
                d='M148.43,313.767h-18.625v32.789h19.791v-32.789H148.43z M147.264,316.099
                               c0,2.07,0,26.055,0,28.125c-1.883,0-13.244,0-15.127,0c0-2.07,0-26.055,0-28.125
                               C134.02,316.099,145.381,316.099,147.264,316.099z'
              />
            </g>
            <path
              fill={color}
              d='M143.558,340.109v-6.701h-1.015v-1.218h-4.264v-5.482h1.219v2.032h3.449v-9.746
                           h-3.449v5.482h-1.219h-2.437v2.231v15.229h0.61h1.826h6.904v-1.828H143.558z M138.279,334.627h1.829v5.482h-1.829V334.627z'
            />
            <path
              fill={color}
              d='M114.321,176.854c-23.215,0-42.035,18.821-42.035,42.032
                           c0,16.041,8.993,29.978,22.206,37.061l2.981-3.069c-12.506-6.211-21.125-19.078-21.125-33.991
                           c0-20.97,17.001-37.971,37.973-37.971c20.968,0,37.972,17.001,37.972,37.971c0,13.855-7.446,25.941-18.529,32.573
                           c0.54,0.667,1.128,2.398,1.539,3.811c12.571-7.267,21.052-20.82,21.052-36.384
                           C156.354,195.675,137.532,176.854,114.321,176.854z'
            />
            <polyline
              fill={color}
              points='91.375,257.873 98.075,248.735 100.513,248.125 100.715,249.954 
                           94.825,261.123 '
            />
            <path
              fill={color}
              d='M129.142,246.097l-2.437,2.233l7.105,10.558l3.25-1.625
                           C137.061,257.263,130.768,245.894,129.142,246.097z'
            />
            <polyline
              fill={color}
              points='128.534,248.129 121.223,256.453 121.223,269.449 118.178,269.449 
                           118.178,259.701 106.604,259.701 106.604,269.243 104.979,267.62 104.979,256.453 98.278,248.737 99.496,247.315 
                           107.213,256.249 112.492,256.249 112.492,253.001 113.914,253.001 113.914,256.656 118.989,256.656 129.142,245.486 '
            />
            <g>
              <path
                fill={color}
                d='M126.703,222.948c0,0-4.569-3.655-11.675-1.218
                               c-0.307,12.488-0.307,12.488-0.307,12.488s1.831-1.219,2.339,0.813c0.912-1.93,0.503-2.844-1.12-2.437
                               c-0.201-4.874,0-7.718,0-7.718l10.66-0.304c0,0,0.41,14.21-0.099,16.851c-0.51,2.643-5.482,8.429-10.154,9.544
                               c8.528-0.912,12.081-2.743,12.081-4.975s0-11.167,0-11.167s2.233-1.624,2.233-4.061c0-2.435,0.104-4.064,0.104-4.064
                               s10.454-10.659-0.814-24.464c-5.685-6.906-17.867-5.483-18.781-5.179c-0.203,1.423,0,2.132,0,2.132
                               s15.43-3.856,21.218,10.256c3.045,10.251-2.641,15.429-2.641,15.429s0.307,6.7-0.608,7.514
                               c-0.914,0.813-1.422-0.304-1.321-0.607c0.104-0.305,0.104-8.935,0.104-8.935s1.521-2.944,0.812-7.716
                               c-0.709-4.771-0.508-8.325-0.508-8.325s-1.522,2.741-0.304,6.906c1.218,4.16-0.508,9.133-1.522,9.54'
              />
              <path
                fill={color}
                d='M114.219,240.206c0,0,0.353-1.367,2.688-0.709c2.336,0.661,3.303,1.067,3.25,0.307
                               c-0.052-0.762,0.252-0.254,0.405,0.455c0.151,0.711-0.205,0.914-1.522,0.763c-1.319-0.152-4.465,0.252-4.821,0.252'
              />
              <path
                fill={color}
                d='M113.914,242.441c0,0,3.855-0.052,4.212-0.406s0.812,0.254,0.457,0.406
                               c-0.354,0.151-3.502,0.915-4.62,0.915'
              />
              <path
                fill={color}
                d='M119.495,242.341c0,0-0.354,5.178-2.181,6.143
                               c-1.827,0.963-4.924,0.558-4.924,0.558l0.05,1.676c0,0,2.944,1.166,5.532,0.051
                               C120.563,249.651,120.307,246.657,119.495,242.341z'
              />
              <path
                fill={color}
                d='M119.141,224.677c-0.048,0.15,0,1.522,0,1.522h3.959l0.204-2.284'
              />
              <path
                fill={color}
                d='M126.933,232.239c0,0-0.229,1.445-1.905,2.817
                               c-1.674,1.369-1.826,5.102-1.826,5.102s0.609-3.428,1.902-4.266c1.296-0.836,2.741-0.762,2.741-2.284'
              />
            </g>
            <g>
              <path
                fill={color}
                d='M101.281,222.948c0,0,4.572-3.655,11.677-1.218
                               c0.304,12.488,0.304,12.488,0.304,12.488s-1.83-1.219-2.337,0.813c-0.914-1.93-0.505-2.844,1.119-2.437
                               c0.203-4.874,0-7.718,0-7.718l-10.66-0.304c0,0-0.41,14.21,0.1,16.851c0.509,2.643,5.481,8.429,10.153,9.544
                               c-8.528-0.912-12.081-2.743-12.081-4.975s0-11.167,0-11.167s-2.234-1.624-2.234-4.061c0-2.435-0.102-4.064-0.102-4.064
                               s-10.456-10.659,0.813-24.464c5.685-6.906,17.867-5.483,18.781-5.179c0.201,1.423,0,2.132,0,2.132
                               s-15.431-3.856-21.218,10.256c-3.046,10.251,2.639,15.429,2.639,15.429s-0.305,6.7,0.608,7.514
                               c0.915,0.813,1.424-0.304,1.321-0.607c-0.103-0.305-0.103-8.935-0.103-8.935s-1.521-2.944-0.811-7.716
                               c0.709-4.771,0.506-8.325,0.506-8.325s1.522,2.741,0.304,6.906c-1.219,4.16,0.507,9.133,1.523,9.54'
              />
              <path
                fill={color}
                d='M113.766,240.206c0,0-0.351-1.367-2.688-0.709c-2.337,0.661-3.303,1.067-3.25,0.307
                               c0.053-0.762-0.253-0.254-0.405,0.455c-0.152,0.711,0.205,0.914,1.522,0.763c1.318-0.152,4.464,0.252,4.821,0.252'
              />
              <path
                fill={color}
                d='M114.071,242.441c0,0-3.855-0.052-4.212-0.406
                               c-0.357-0.354-0.812,0.254-0.457,0.406c0.354,0.151,3.503,0.915,4.619,0.915'
              />
              <path
                fill={color}
                d='M108.489,242.341c0,0,0.354,5.178,2.182,6.143c1.828,0.963,4.923,0.558,4.923,0.558
                               l-0.05,1.676c0,0-2.944,1.166-5.533,0.051C107.422,249.651,107.677,246.657,108.489,242.341z'
              />
              <path
                fill={color}
                d='M108.843,224.677c0.049,0.15,0,1.522,0,1.522h-3.959l-0.202-2.284'
              />
              <path
                fill={color}
                d='M101.052,232.239c0,0,0.229,1.445,1.904,2.817c1.675,1.369,1.828,5.102,1.828,5.102
                               s-0.609-3.428-1.904-4.266c-1.294-0.836-2.741-0.762-2.741-2.284'
              />
            </g>
          </g>
        </g>
      </g>
    </switch>
  </Svg>
)

export default withSizes(mapSizesToProps)(Logo)
