import {BLOCKS, INLINES, MARKS} from "@contentful/rich-text-types";
import {theme} from "./theme";
import React from "react";
import styled from 'styled-components';

const StyledLi = styled.li`
  color: ${(props) => props.theme.colors.cream};
`


export const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_, children) => (
            <p style={{textAlign: 'left', color: theme.colors.cream}}>{children}</p>
        ),
        [BLOCKS.HEADING_1]: (_, children) => (
            <h1 style={{textAlign: 'left', color: theme.colors.cream}}>
                {children}
            </h1>
        ),
        [BLOCKS.HEADING_2]: (_, children) => (
            <h2 style={{textAlign: 'left', color: theme.colors.cream}}>
                {children}
            </h2>
        ),
        [BLOCKS.HEADING_3]: (_, children) => (
            <h3 style={{textAlign: 'left', color: theme.colors.cream}}>
                {children}
            </h3>
        ),
        [BLOCKS.HEADING_4]: (_, children) => (
            <h4 style={{textAlign: 'left', color: theme.colors.cream}}>
                {children}
            </h4>
        ),
        [BLOCKS.HEADING_5]: (_, children) => (
            <h5 style={{textAlign: 'left', color: theme.colors.cream}}>
                {children}
            </h5>
        ),
        [INLINES.HYPERLINK]: (node, children) => (
            <a
                href={node.data['uri']}
                style={{color: 'white'}}
                target='_self'
                rel='noopener noreferrer'>
                {children}
            </a>
        ),
        [BLOCKS.LIST_ITEM]: (_, children) => (
            <StyledLi>
                {children}
            </StyledLi>
        )
    },
}